<template>
  <!-- eslint-disable -->
  <b-sidebar
    id="add-new-speciality"
    :visible="isAdd"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    no-close-on-backdrop
    @change="(val) => $emit('update:is-add', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ isEdit ? 'Editar' : 'Agregar nueva' }} Actividad</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click=";[hide(), resetForm()]"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ invalid }" ref="refFormObserver">
        <!-- Form -->

        <b-form class="p-2" @submit.prevent="onSubmit(items)">
          <validation-provider #default="{ errors }" name="code" rules="requeridoE">
            <b-form-group label="Codigo" label-for="code">
              <b-form-input
                id="code"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Codigo"
                v-model="items.code"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="description" rules="">
            <b-form-group label="Descripcion" label-for="description">
              <b-form-input
                id="description"
                trim
                :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Descripcion"
                v-model="items.description"
              />
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="project" rules="requeridoE">
            <b-form-group label="Proyecto" label-for="project">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="proyectos"
                label="code"
                input-id="project"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Proyecto"
                v-model="items.project_id"
                @input="changeProyect(items.project_id)"
                class="select-obra"
                disabled
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="speciality" rules="requeridoE">
            <b-form-group label="Especialidad" label-for="speciality">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="especialidades"
                label="code"
                input-id="speciality"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Especialidad"
                v-model="items.speciality_id"
                @input="changeEspe(items.speciality_id)"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="location_1_id" rules="requeridoE">
            <b-form-group label="Localidad 1" label-for="location_1_id">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="local1"
                label="code"
                input-id="location_1_id"
                :reduce="(local1) => local1.id"
                placeholder="Localidad 1"
                v-model="items.location_1_id"
                @input="changeLocal(items.location_1_id, 1)"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="location_2_id" rules="">
            <b-form-group label="Localidad 2" label-for="location_2_id">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="local2"
                label="code"
                input-id="location_2_id"
                :reduce="(local2) => local2.id"
                placeholder="Localidad 2"
                v-model="items.location_2_id"
                @input="changeLocal(items.location_2_id, 2)"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="location_3_id" rules="">
            <b-form-group label="Localidad 3" label-for="location_3_id">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="local3"
                label="code"
                input-id="location_3_id"
                :reduce="(local3) => local3.id"
                placeholder="Localidad 3"
                v-model="items.location_3_id"
                @input="changeLocal(items.location_3_id, 3)"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="location_4_id" rules="">
            <b-form-group label="Localidad 4" label-for="location_4_id">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="local4"
                label="code"
                input-id="location_4_id"
                :reduce="(local4) => local4.id"
                placeholder="Localidad 4"
                v-model="items.location_4_id"
                class="select-obra"
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
              <small
                class="text-danger alert"
                :style="{ height: (errors.length > 0 ? 20 : 0) + 'px' }"
                >{{ errors[0] }}</small
              >
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <b-spinner v-if="isDisabled" small />
              <span v-if="isDisabled" class="px-1">guardando...</span>
              <span v-else>{{ isEdit ? 'Actualizar' : 'Agregar' }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click=";[hide(), resetForm()]"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import LocalizationService from '@/services/LocalizationService'
import ProjectsService from '@/services/ProjectsService'
import SpecialityService from '@/services/SpecialityService'
Vue.use(BootstrapVue)
export default {
  directives: {
    Ripple
  },
  components: {
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  props: {
    isAdd: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      required,
      // speciality: {
      //   title: ''
      // },
      isEdit: false,
      isDisabled: false,
      proyectos: [],
      especialidades: [],
      local1: [],
      local2: [],
      local3: [],
      local4: [],
      items: {
        code: '',
        description: '',
        project_id: JSON.parse(localStorage.getItem('project_id')),
        speciality_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null
      },
      temp: {}
    }
  },
  mounted() {
    this.getSelect()
     this.changeProyect(JSON.parse(localStorage.getItem('project_id')))
  },
  methods: {
    async changeProyect(id) {
      this.$refs.refFormObserver.reset()
      this.items.speciality_id = null
      this.items.location_1_id = null
      this.items.location_2_id = null
      this.items.location_3_id = null
      this.items.location_4_id = null
      this.local1 = []
      this.local2 = []
      this.local3 = []
      this.local4 = []
      let url =
        `?limit=100&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respEspe = await SpecialityService.getSpecialitys(url, this.$store)
      if (respEspe.status) {
        this.especialidades = respEspe.data.rows
      }
      let url1 =
        `?limit=100&filter=` + JSON.stringify([{ keyContains: 'project_id', key: 'equals', value: id }])
      const respLocal1 = await LocalizationService.getLocations1(url1, this.$store)
      console.log('respLocal1',respLocal1)
      if (respLocal1.status) {
        this.local1 = respLocal1.data.rows
      }
    },
    
    async changeEspe(id) {
      // this.$refs.refFormObserver.reset()
      // this.items.location_1_id = null
      // this.items.location_2_id = null
      // this.items.location_3_id = null
      // this.items.location_4_id = null
      // this.local1 = []
      // this.local2 = []
      // this.local3 = []
      // this.local4 = []
      // let url =
      //   `?filter=` + JSON.stringify([{ keyContains: 'speciality_id', key: 'equals', value: id }])
      // const respLocal1 = await LocalizationService.getLocations1(url, this.$store)
      // console.log('respLocal1',respLocal1)
      // if (respLocal1.status) {
      //   this.local1 = respLocal1.data.rows
      // }
    },
    async changeLocal(id, level) {
      this.$refs.refFormObserver.reset()
      if (level == 1) {
        this.items.location_2_id = null
        this.items.location_3_id = null
        this.items.location_4_id = null
        this.local2 = []
        this.local3 = []
        this.local4 = []

        let url =
          `?limit=100&filter=` + JSON.stringify([{ keyContains: 'location_1_id', key: 'equals', value: id }])
        const respLocal2 = await LocalizationService.getLocations2(url, this.$store)
        if (respLocal2.status) {
          this.local2 = respLocal2.data.rows
        }
      } else if (level == 2) {
        this.items.location_3_id = null
        this.items.location_4_id = null
        this.local3 = []
        this.local4 = []
        let url =
          `?limit=100&filter=` + JSON.stringify([{ keyContains: 'location_2_id', key: 'equals', value: id }])
        const respLocal3 = await LocalizationService.getLocations3(url, this.$store)
        if (respLocal3.status) {
          this.local3 = respLocal3.data.rows
        }
      } else {
        this.items.location_4_id = null
        this.local4 = []
        let url =
          `?limit=100&filter=` + JSON.stringify([{ keyContains: 'location_3_id', key: 'equals', value: id }])
        const respLocal4 = await LocalizationService.getLocations4(url, this.$store)
        if (respLocal4.status) {
          this.local4 = respLocal4.data.rows
        }
      }
    },
    async getSelect() {
      const url = `?limit=100&`
      const resp = await ProjectsService.getProyectos(url, this.$store)
      if (resp.status) {
        this.proyectos = resp.data.rows
      }
    },
    setData(items) {
      if (Object.entries(items).length !== 0) {
        if (items.project_id != null) {
          this.changeProyect(items.project_id)
        }
        // if (items.speciality_id != null) {
        //   this.changeEspe(items.speciality_id)
        // }
        if (items.location_1_id != null) {
          this.changeLocal(items.location_1_id, 1)
        }
        if (items.location_2_id != null) {
          this.changeLocal(items.location_2_id, 2)
        }
        if (items.location_3_id != null) {
          this.changeLocal(items.location_3_id, 3)
        }
        this.temp = items
        this.items = items
        console.log('this.items',this.items)
        this.isEdit = true
      } else {
        this.temp = {}
        this.items = {
          code: '',
          description: '',
          project_id: JSON.parse(localStorage.getItem('project_id')),
          speciality_id: null,
          location_1_id: null,
          location_2_id: null,
          location_3_id: null,
          location_4_id: null
        }
        this.isEdit = false
      }
      console.log('temp EN ADD', this.temp)
    },
    resetForm() {
      this.$refs.refFormObserver.reset()
      this.isEdit = false
      this.items = {
        code: '',
        description: '',
        project_id: JSON.parse(localStorage.getItem('project_id')),
        speciality_id: null,
        location_1_id: null,
        location_2_id: null,
        location_3_id: null,
        location_4_id: null
      }
    },
    async onSubmit(data) {
      console.log('data', data)
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          this.isDisabled = true
          let resp = ''
          let datos = {}
          datos.code = data.code
          datos.description = data.description
          datos.project_id = data.project_id
          datos.speciality_id = data.speciality_id
          datos.location_1_id = data.location_1_id
          datos.location_2_id = data.location_2_id
          datos.location_3_id = data.location_3_id
          datos.location_4_id = data.location_4_id
          if (this.isEdit == false) {
            resp = await LocalizationService.saveActivity(datos, this.$store)
          } else {
            let id = data.id
            resp = await LocalizationService.updateActivity(id, datos, this.$store)
          }
          console.log('datos', datos)
          if (resp.status) {
            this.$swal({
              title: this.isEdit == true ? 'Actualizado' : 'Registrado',
              text:
                'La actividad ha sido ' + (this.isEdit == true ? 'actualizado.' : 'registrado.'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            console.log('this.$parent.$parent', this.$parent.$parent)
            this.$parent.$parent.$parent.getData()
            this.$emit('update:is-add', false)
            this.resetForm()
          } else {
            this.$swal({
              title: 'Error!',
              text:
                ' Hubo un error al ' +
                (this.isEdit == true ? 'actualizar' : 'registrar') +
                ' la actividad',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
          this.isDisabled = false
        } else {
          console.log('no validado')
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
