/* eslint-disable*/
import VueStoreService from './VueStoreService';
const LocalsService = {
    //LOCALIZACION
    async getActivitys(filter,store){
        var resp = await VueStoreService.get("activity"+filter, store)
        return resp;
    },
    async saveActivity(data,store){
        var resp = await VueStoreService.post("activity",data,store)
        return resp;
    },
    async getActivityById(activity_id,store){
        var resp = await VueStoreService.get( "activity/"+activity_id,store)
        return resp;
    },
    async updateActivity(activity_id,data,store){
        var resp = await VueStoreService.put("activity/"+activity_id,data,store)
        return resp;
    },

    async deleteActivity(activity_id,store){
        var resp = await VueStoreService.delete("activity/"+activity_id,store)
        return resp;
    },
    //IMPORTAR
    async saveImport(data,store){
        var resp = await VueStoreService.post("activity/upload",data,store)
        return resp;
    },
    //LOCALIZACION HIJOS
    //TREEVIEW
    async getTreeview(id_project,store){
        var resp = await VueStoreService.get("location-1/treeview/"+id_project, store)
        return resp;
    },

    //LOCATION 1
    async getLocations1(url,store){
        var resp = await VueStoreService.get("location-1"+url,store)
        return resp;
    },
    async getLocation1(id,store){
        var resp = await VueStoreService.get("location-1/"+id,store)
        return resp;
    },
    async saveLocation1(data,store){
        var resp = await VueStoreService.post("location-1",data,store)
        return resp;
    },
    async updateLocation1(id,data,store){
        var resp = await VueStoreService.put("location-1/"+id,data,store)
        return resp;
    },
    async deleteLocation1(id,store){
        var resp = await VueStoreService.delete("location-1/"+id,store)
        return resp;
    },

    //LOCATION 2
    async getLocations2(url,store){
        var resp = await VueStoreService.get("location-2"+url,store)
        return resp;
    },
    async getLocation2(id,store){
        var resp = await VueStoreService.get("location-2/"+id,store)
        return resp;
    },
    async saveLocation2(data,store){
        var resp = await VueStoreService.post("location-2",data,store)
        return resp;
    },
    async updateLocation2(id,data,store){
        var resp = await VueStoreService.put("location-2/"+id,data,store)
        return resp;
    },
    async deleteLocation2(id,store){
        var resp = await VueStoreService.delete("location-2/"+id,store)
        return resp;
    },

    //LOCATION 3
    async getLocations3(url,store){
        var resp = await VueStoreService.get("location-3"+url,store)
        return resp;
    },
    async getLocation3(id,store){
        var resp = await VueStoreService.get("location-3/"+id,store)
        return resp;
    },
    async saveLocation3(data,store){
        var resp = await VueStoreService.post("location-3",data,store)
        return resp;
    },
    async updateLocation3(id,data,store){
        var resp = await VueStoreService.put("location-3/"+id,data,store)
        return resp;
    },
    async deleteLocation3(id,store){
        var resp = await VueStoreService.delete("location-3/"+id,store)
        return resp;
    },

    //LOCATION 4
    async getLocations4(url,store){
        var resp = await VueStoreService.get("location-4"+url,store)
        return resp;
    },
    async getLocation4(id,store){
        var resp = await VueStoreService.get("location-4/"+id,store)
        return resp;
    },
    async saveLocation4(data,store){
        var resp = await VueStoreService.post("location-4",data,store)
        return resp;
    },
    async updateLocation4(id,data,store){
        var resp = await VueStoreService.put("location-4/"+id,data,store)
        return resp;
    },
    async deleteLocation4(id,store){
        var resp = await VueStoreService.delete("location-4/"+id,store)
        return resp;
    },
}

export default LocalsService;