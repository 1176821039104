<template>
  <!-- eslint-disable -->
  <div>
    <add-edit :is-add.sync="isAdd" ref="activityAdd"> </add-edit>
    <b-card no-body>
      <b-card-header class="w-100">
        <b-row class="w-100">
          <b-col md="5" class="mb-md-0 mb-1">
            <b-form-group label="Proyecto" label-for="project" class="mr-2">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="proyectos"
                label="code"
                input-id="project"
                :reduce="(proyectos) => proyectos.id"
                placeholder="Proyecto"
                v-model="project_id"
                class="select-obra"
                @input="filter"
                disabled
              >
                <template v-slot:selected-option="option">
                  {{ option.code }} - {{ option.description }}
                </template>
                <template slot="option" slot-scope="option">
                  {{ option.code }} - {{ option.description }}
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            md="7"
            class="mb-md-0 mb-2 d-flex justify-content-center justify-content-md-end align-items-center"
          >
            <div class="d-flex">
              <!-- <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mr-1"
                @click.prevent="clean"
              >
                <span class="align-middle">Ver Todos</span>
              </b-button> -->
              <b-button class="mr-2 mb-1 mb-md-0" variant="warning" @click="importData">
                  <span class="text-nowrap"> <feather-icon icon="DownloadIcon" /> Importar </span>
                </b-button>
            </div>
            
            <b-button variant="primary" class="mb-25" @click="addActivity">
              <span class="text-nowrap"
                ><feather-icon icon="PlusCircleIcon" size="15" /> Agregar</span
              >
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable*/
import Vue from 'vue'
import { BootstrapVue, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import addEdit from './add-edit.vue'
import ProjectsService from '@/services/ProjectsService'
Vue.use(BootstrapVue)
export default {
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  data() {
    return {
      isAdd: false,
      isUpdate: false,
      see_more: false,
      project_id: JSON.parse(localStorage.getItem('project_id')),
      proyectos: [],
      showEntrie: 10,
      arrayFilters: [],
      userData: JSON.parse(localStorage.getItem('userData')),
      user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
    }
  },
  components: {
    vSelect,
    addEdit
  },
  mounted() {
    console.log('userData', this.userData)
    this.filter()
    this.getData()
  },
  methods: {
    async getData() {
      const url = `?limit=100&`
      const respProyectos = await ProjectsService.getProyectos(url, this.$store)
     
      if (respProyectos.status) {
        this.proyectos = respProyectos.data.rows
        // if (respProyectos.data.rows.length > 0) {
        //   this.project_id = respProyectos.data.rows[0].id
        // } else {
        //   if (this.userData.role == 'planner') {
        //     this.project_id = 0
        //   }
        // }
      }
      // this.filter()
    },
    importData() {
      this.$router.push('/mantenedores/actividad/importar-datos')
    },
    clickSeeMore() {
      this.see_more = !this.see_more
    },
    addActivity() {
      this.isAdd = true
      this.$refs.activityAdd.setData({})
    },
    filter() {
      var arrayFilter = []
      if (this.project_id != '' && this.project_id != null) {
        arrayFilter.push({
          keyContains: 'project_id',
          key: 'equals',
          value: this.project_id
        })
      }
      console.log('arrayFilter', arrayFilter)
      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    },
    clean() {
      this.project_id = null
      var arrayFilter = []
      const proyects = []
      if (this.user_role != 'admin') {
        if (this.proyectos.length > 0) {
          for (let index = 0; index < this.proyectos.length; index++) {
            const element = this.proyectos[index]
            proyects.push(element.id)
          }
        }
        if (proyects.length > 0) {
          arrayFilter.push({
            keyContains: 'project_id',
            key: 'in',
            value: JSON.stringify(proyects)
          })
        }
      }

      this.$parent.$parent.arrayFilters = arrayFilter
      this.$parent.$parent.getData()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.body_card {
  display: block;
  transition: 0.3s;
}
</style>
